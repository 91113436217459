import { GetServerSideProps } from "next"

import { fetchGalleries } from "@app/features/galleries/requests"
import { fetchGeneralSettings } from "@app/features/general-settings/requests"

import { getMeta } from "@app/features/meta/utils"
import { getPublicGalleries } from "@app/features/gallery/utils"

import { REQUEST_TYPE } from "@app/api/constants"
import { HOMEPAGE_DOMAINS } from "@app/constants"
import { HOMEPAGE_META_KEY } from "@app/features/meta/constants"

import { Footer } from "@app/ui/footer"
import { GlobalStateProvider } from "@app/contexts"
import { Meta } from "@app/features/meta/components"
import { Maintenance } from "@app/features/maintenance/components"
import { Header, Galleries } from "@app/features/portfolio/components"
import { TagsSelector } from "@app/features/tags/components"

const PortfolioPage = ({ galleries, generalSettings, isDisplayHomePage = false }) => {
  if (isDisplayHomePage) return <Maintenance>Галереи Vigbo</Maintenance>

  return (
    <GlobalStateProvider value={generalSettings}>
      <Meta {...getMeta({ key: HOMEPAGE_META_KEY, settings: generalSettings })}></Meta>
      <Header></Header>
      <TagsSelector galleries={galleries} />
      <Galleries galleries={galleries}></Galleries>
      <Footer></Footer>
    </GlobalStateProvider>
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const domain: string = ctx.req.headers.host

  if (HOMEPAGE_DOMAINS.includes(domain)) {
    return {
      props: { isDisplayHomePage: true },
    }
  }

  const galleries = await fetchGalleries(domain, ctx)
  const generalSettings = await fetchGeneralSettings({ requestType: REQUEST_TYPE.SERVER, ctx, domain })

  if (!generalSettings || !galleries) {
    return {
      notFound: true,
    }
  }

  const publicGalleries = getPublicGalleries(galleries)

  return {
    props: {
      galleries: publicGalleries,
      generalSettings,
    },
  }
}

export default PortfolioPage
